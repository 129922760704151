import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import throttle from 'lodash/throttle';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  data() {
    return {
      isOpened: false
    };
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.addEventListener('scroll', this.onScroll);
  },

  methods: {
    onResize: throttle(function() {
      if (this.windowWidth >= parseInt(fullConfig.theme.screens.lg, 10) && this.isOpened) {
        this.isOpened = false;
      }
    }, 300)
  }
};
