<template>
  <span class="">
    <button class="  text-green-primary font-bold relative outcome" :data-tippy-content="this.body">
      <slot />
    </button>
  </span>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import axios from '@common/http/axios';

export default {
  props: {
    glossary: { required: true, type: String }
  },

  data() {
    return {
      body: ''
    };
  },

  mounted() {
    this.fetchModalData();
    setTimeout(() => {
      tippy('[data-tippy-content]', {
        allowHTML: true,
        theme: 'idh'
      });
    }, 2000);
  },

  methods: {
    async fetchModalData() {
      try {
        const { data } = await axios.get(`/api/glossaries/${this.glossary}`);
        this.body = data.body;
      } catch (ex) {
        console.error(ex);
        this.body = 'We failed to load the content. Sorry for the inconvenience.';
      } finally {
        // this.loading = false;
      }
    }
  }
};
</script>
<style>
.tippy-box[data-theme~='idh'] {
  @apply flex flex-col p-4 rounded-md bg-almost-black text-white font-primary text-base font-normal;
  width: 320px;
}
</style>
