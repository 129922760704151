<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :fitParent="true"
  >
    <div class="flex justify-center">
      <div class="overlay-modal-content bg-white rounded-3xl relative">
        <div class="flex flex-col">
          <!-- Content -->
          <div class="py-16 px-6 sm:px-10 md:px-24">
            <OverlayLoading v-if="loading" />
            <div v-else>
              <h2 class="text-3.5xl text-green-primary font-bold mb-6">{{ title }}</h2>
              <component :is="bodyComponent" />
            </div>
          </div>

          <!-- Footer -->
          <div class="bg-green-light-cyan p-8 flex justify-center flex-shrink-0 rounded-b-3xl">
            <button @click="close" class="btn btn-green px-5">Close</button>
          </div>
        </div>

        <!-- Close button -->
        <button class="absolute top-5 right-5" @click="close">
          <CloseIcon class="hover:opacity-80" />
        </button>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import Vue from 'vue';
import axios from '@common/http/axios';
import CloseIcon from './CloseIcon.vue';
import OverlayLoading from './OverlayLoading.vue';
import { VueFinalModal } from 'vue-final-modal';

export default {
  components: {
    CloseIcon,
    OverlayLoading,
    VueFinalModal
  },

  props: {
    overlay: { required: true, type: String }
  },

  data() {
    return {
      loading: true,
      title: 'Loading',
      body: '<p class="block paragraph-block">Loading... please wait!</p>.',
      error: null,
      bodyComponent: null
    };
  },

  mounted() {
    this.fetchModalData();
  },

  methods: {
    async fetchModalData() {
      try {
        const { data } = await axios.get(`/api/overlays/${this.overlay}`);
        this.title = data.title;
        this.body = data.body;
        this.compileBodyAsComponent();
      } catch (ex) {
        console.error(ex);
        this.title = 'Oops';
        this.body = 'We failed to load the content. Sorry for the inconvenience.';
      } finally {
        this.loading = false;
      }
    },

    compileBodyAsComponent() {
      const res = Vue.compile(`<div class="editor-content">${this.body}</div>`);

      this.bodyComponent = Vue.component('BodyComponent', {
        render: res.render,
        staticRenderFns: res.staticRenderFns
      });
    }
  }
};
</script>

<!-- Global styles -->
<style>
div.vfm__content {
  @apply h-screen overflow-auto;
  padding: 15vh 0;
}
div.vfm__overlay {
  background: rgba(0, 0, 0, 1) !important;
}
</style>

<!-- Local styles -->
<style scoped lang="scss">
::v-deep {
  .overlay-modal-content {
    max-width: 95vw;
    width: 800px;

    @screen md {
      max-width: 80vw;
    }
  }
}
</style>
