<template>
  <div>
    <Skeleton height="48px" />
    <!-- Some space between -->
    <div class="h-6"></div>
    <Skeleton :count="8" />
  </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';

export default {
  components: { Skeleton }
};
</script>
