<template>
  <span>
    <button
      @click="show = true"
      class="text-blue-deep font-bold hover:underline text-left contents"
    >
      <slot />
    </button>
    <portal to="overlay-modals">
      <OverlayModal v-if="show" :overlay="overlay" v-model="show" :order="1" />
    </portal>
    <portal to="overlay-modals">
      <OverlayModal v-if="show" :overlay="overlay" v-model="show" :order="2" />
    </portal>
  </span>
</template>

<script>
import OverlayModal from './OverlayModal.vue';

export default {
  components: { OverlayModal },

  props: {
    overlay: { required: true, type: String }
  },

  data() {
    return {
      show: false
    };
  }
};
</script>
