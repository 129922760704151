export default function() {
//   let content = document.querySelector('.editor-content');
//   if (content) {
//     let links = content.getElementsByTagName('a');
//     for (let link of links) {
//       link.setAttribute('target', '_blank');
//     }
//   }

  // Open all links in search results in new tab
  let searchResults = document.querySelectorAll('.search-result-link');
  if (searchResults) {
    for (let link of searchResults) {
      link.setAttribute('target', '_blank');
    }
  }
}
